import { useAlert } from '@/modules/shared/utils/';
import androidAppNotifPermissionStatusPlugin from '@/plugins/android/app-notif-permission-check.js';
import iosAppNotifSettingPlugin from '@/plugins/ios/app-notif-setting.js';
import PushNotification from '@/services/shared/helper/push-notification';
import { Capacitor } from '@capacitor/core';
import { PushNotifications } from '@capacitor/push-notifications';
import { isPlatform } from '@ionic/vue';
import { AndroidSettings, NativeSettings } from 'capacitor-native-settings';
import { nextTick, ref } from 'vue';
import { useConfig } from '..';
import { ACTIONS } from '../../modules/b2b/store/product/actions';
import { useToast } from '../global';

export const useProductSubscribeNotification = () => {
  const { t, storage, router, storePath, store } = useConfig();
  const { createAlertConfirmTwoAction } = useAlert();
  const { showToast } = useToast();
  const toggleRef = ref(null);
  const isSubscribed = ref(null);
  const productId = ref(null);

  const getData = (data) => {
    const { id, is_subscribed_to_notification } = data || {};
    isSubscribed.value = is_subscribed_to_notification;
    productId.value = id;
  };

  const subscribeToNotification = async () => {
    await store.dispatch(`b2b/product/${ACTIONS.SUBSCRIBE_PRODUCT_STOCK_NOTIF}`, productId.value);
    showToast(t(`you_will_be_notified`));
  };
  const unSubscribeToNotification = async () => {
    await store.dispatch(`b2b/product/${ACTIONS.UNSUBSCRIBE_PRODUCT_STOCK_NOTIF}`, productId.value);
    showToast(t(`you_will_not_be_notified`));
  };

  const pushNotifPermissionStatus = async () => {
    if (!Capacitor.isNativePlatform()) return;
    const permStatus = await PushNotifications.checkPermissions();
    return permStatus.receive;
  };

  const dismissAlert = () => {
    nextTick(() => {
      if (toggleRef.value) {
        toggleRef.value.$el.checked = false;
      }
    });
  };

  const showAlert = () => {
    createAlertConfirmTwoAction(
      t('notif_alert_label'),
      t('notif_alert_desc'),
      t('allow'),
      processSubscriptionInit,
      t('not_allow'),
      'alert-subscribe-product-notif',
      dismissAlert
    );
  };

  const openAppSettings = () => {
    if (!Capacitor.isNativePlatform()) return;
    isPlatform('ios')
      ? iosAppNotifSettingPlugin.onRedirect()
      : NativeSettings.openAndroid({
          option: AndroidSettings.AppNotification
        });
  };

  const processSubscriptionInit = async () => {
    await PushNotification.init(router, storage, storePath);
    await openAppSettings();
    await subscribeToNotification();
  };

  const onToggleSubscribe = async (e) => {
    if (!Capacitor.isNativePlatform()) {
      if (e.detail.checked) {
        subscribeToNotification();
      } else {
        unSubscribeToNotification();
      }
    } else {
      const permissionStatus = await pushNotifPermissionStatus();
      if (e.detail.checked) {
        if (isPlatform('ios')) {
          permissionStatus === `granted` ? subscribeToNotification() : showAlert();
        } else {
          const { value } = await androidAppNotifPermissionStatusPlugin.areNotificationsEnabledStatus();
          value ? subscribeToNotification() : showAlert();
        }
      } else {
        unSubscribeToNotification();
      }
    }
  };

  return {
    onToggleSubscribe,
    getData,
    toggleRef,
    isSubscribed
  };
};
