import { apolloClient } from '@/main';
import { getProductDetails } from '@/modules/shared/services/graphql/product.js';
import { ref } from 'vue';
import { useStore } from 'vuex';
import { ACTIONS } from '../../store/product/actions';

const getSpecsByKey = (item) => {
  let specsByKey = {};
  if (!item) return specsByKey;

  for (const skusItem of item.skusData) {
    const data = skusItem.specs ?? '{"Single_Variant":"true"}';
    const parseSpecs = JSON.parse(data);

    for (const [spec, value] of Object.entries(parseSpecs)) {
      const specArr = specsByKey[spec] ? specsByKey[spec] : [];
      const index = specArr.findIndex((spec) => spec.value === value);
      let ids = [];

      if (index === -1) {
        ids = [skusItem.sku_id];
        specArr.push({ value, ids });
      } else {
        ids = [...specArr[index].ids, skusItem.sku_id];
        specArr[index] = {
          ...specArr[index],
          ids
        };
      }

      specsByKey[spec] = specArr;
    }
  }
  return specsByKey;
};

const getSpecsOosByKey = (oosList) => {
  let specsOosByKey = {};
  if (!oosList) return specsOosByKey;

  for (const skusItem of oosList) {
    const data = skusItem.specs ?? '{"Single_Variant":"true"}';
    const parseSpecs = JSON.parse(data);

    for (const [spec, value] of Object.entries(parseSpecs)) {
      const specArr = specsOosByKey[spec] ? specsOosByKey[spec] : [];
      const index = specArr.findIndex((spec) => spec.value === value);
      let ids = [];

      if (index === -1) {
        ids = [skusItem.sku_id];
        specArr.push({ value, ids });
      } else {
        ids = [...specArr[index].ids, skusItem.sku_id];
        specArr[index] = {
          ...specArr[index],
          ids
        };
      }

      specsOosByKey[spec] = specArr;
    }
  }
  return specsOosByKey;
};

const getSpecsById = (item) => {
  let specsById = [];

  if (!item) return specsById;

  for (const skusItem of item.skusData) {
    let specsByIdItem = {};
    specsByIdItem[skusItem.sku_id] = [];
    const parseSpecs = JSON.parse(skusItem.specs);

    for (const value of Object.values(parseSpecs)) {
      specsByIdItem[skusItem.sku_id] = [...specsByIdItem[skusItem.sku_id], value];
    }

    specsById.push(specsByIdItem);
  }
  return specsById;
};

const getSkusInfo = (item) => {
  let result = [];

  if (!item) return result;

  for (const skusItem of item.skusData) {
    let skuInfo = {
      skuId: skusItem.sku_id,
      isOos: skusItem.is_out_of_stock,
      isPurchased: skusItem.is_purchased,
      specs: []
    };
    if (skusItem.specs) {
      const parseSpecs = JSON.parse(skusItem.specs);

      for (const value of Object.values(parseSpecs)) {
        skuInfo.specs = [...skuInfo.specs, value];
      }
    }

    result.push(skuInfo);
  }
  return result;
};

const getSpecsOosById = (oosList) => {
  let specsOosById = [];

  if (!oosList) return specsOosById;

  for (const skusItem of oosList) {
    let specsOosByIdItem = {};
    specsOosByIdItem[skusItem.sku_id] = [];
    const parseSpecs = JSON.parse(skusItem.specs);

    for (const value of Object.values(parseSpecs)) {
      specsOosByIdItem[skusItem.sku_id] = [...specsOosByIdItem[skusItem.sku_id], value];
    }

    specsOosById.push(specsOosByIdItem);
  }
  return specsOosById;
};

const findSkuFromSpecs = (specs, list) => {
  const skuIds = [];
  let specsSelected = [];
  let count = 0;
  list.map((item) => {
    const values = item.specs;
    const isExist = specs.every((spec) => values.includes(spec));
    if (isExist) {
      count++;
      const key = item.skuId;
      skuIds.push(parseInt(key));
      if (count === 1) specsSelected = [...values];
    }
  });
  return { skuIds, specsSelected };
};

const findSkuFromOosSpecs = (specs, list) => {
  const skuIds = [];
  let specsOosSelected = [];
  let count = 0;
  list.map((oosList) => {
    const [values] = Object.values(oosList);
    const isExist = specs.every((spec) => values.includes(spec));
    if (isExist) {
      count++;
      const [key] = Object.keys(oosList);
      skuIds.push(parseInt(key));
      if (count === 1) specsOosSelected = [...values];
    }
  });
  return { skuIds, specsOosSelected };
};

const getSpecsInvolvedBySpecs = (specs, list) => {
  let specsInvolved = [];
  let skuIdsInvolved = [];
  list.map((item) => {
    const values = item.specs;

    // check all specs exists in sku
    const isExist = specs.every((spec) => values.includes(spec));
    if (isExist) {
      specsInvolved = [...specsInvolved, ...values];
      skuIdsInvolved = [...skuIdsInvolved, item.skuId];
    }
  });
  return { specsInvolved, skuIdsInvolved };
};

const getSpecsInvolvedByOosSpecs = (specs, list) => {
  let specsOosInvolved = [];
  list.map((oosList) => {
    const [values] = Object.values(oosList);

    // check all specs exists in sku
    const isExist = specs.every((spec) => values.includes(spec));
    if (isExist) {
      specsOosInvolved = [...specsOosInvolved, ...values];
    }
  });
  return specsOosInvolved;
};

const sortListStrings = (items) => {
  for (const key in items) {
    if (Object.hasOwnProperty.call(items, key)) {
      if (items[key].length > 1) {
        items[key].sort((val1, val2) => {
          return val1.value.localeCompare(val2.value);
        });
      }
    }
  }
  return items;
};

const useGetProductDetail = () => {
  const data = ref(null);
  const error = ref(null);
  const store = useStore();
  const getProductDetail = async ({ productId, tenantId, buyerId }) => {
    try {
      const params = {
        buyerId: buyerId?.toString(),
        tenantId: tenantId?.toString(),
        productId: +productId
      };
      const { data: getData, errors: errorData } = await apolloClient.query({
        query: getProductDetails,
        variables: params
      });
      if (errorData) {
        error.value = errorData;
      } else {
        await store.dispatch(`b2b/product/${ACTIONS.SET_PRODUCT_DETAIL_DATA}`, getData);
        data.value = getData;
      }
    } catch (err) {
      error.value = err;
    }
    return {
      data,
      error
    };
  };
  const clearQueryProductDetail = () => {
    data.value = null;
    error.value = null;
  };
  return {
    getProductDetail,
    clearQueryProductDetail
  };
};

export default {
  findSkuFromSpecs,
  getSpecsInvolvedBySpecs,
  getSpecsById,
  getSkusInfo,
  getSpecsByKey,
  sortListStrings,
  useGetProductDetail,
  getSpecsOosByKey,
  getSpecsOosById,
  findSkuFromOosSpecs,
  getSpecsInvolvedByOosSpecs
};
