<template>
  <product-detail-skeleton v-if="!state.isShow && !opacity" />
  <ion-page class="detail-product" v-else>
    <ion-header
      class="ion-no-border"
      mode="ios"
      :class="['header-product', state.isShowHeader || !state.isOnline ? 'animation' : '']"
    >
      <ion-toolbar class="toolbar-header pl-1 pr-2">
        <ion-icon @click="backToPreviousPage" size="large" slot="start" :icon="chevronBackOutline"></ion-icon>
        <ion-icon slot="end" size="large" :icon="shareSocialOutline" @click="openSocialSharing"></ion-icon>
        <ion-label class="label-header-product">{{ state.item?.name }}</ion-label>
      </ion-toolbar>
    </ion-header>
    <ion-content
      ref="productDetailContent"
      class="modal-content"
      :scroll-events="true"
      @ionScroll="handleShowHeader($event)"
      :id="`product-details-${state.item?.id}`"
    >
      <ion-refresher slot="fixed" @ionRefresh="handleRefreshAndRefetchData($event)">
        <ion-refresher-content></ion-refresher-content>
      </ion-refresher>
      <div v-if="!state.isOnline && isIosPlatform" class="mt-5 pt-5"></div>
      <div v-if="!state.isOnline" class="mt-5 pt-5">
        <pull-refresh></pull-refresh>
      </div>
      <div v-if="state.isOnline">
        <div
          style="margin-top: 3.1rem"
          v-show="!state.isShowHeader"
          :class="['back-btn', !state.isShowHeader && 'hidden-back']"
        >
          <ion-icon :icon="chevronBackOutline" @click="backToPreviousPage"></ion-icon>
          <ion-icon :icon="shareSocialOutline" @click="openSocialSharing"></ion-icon>
        </div>
        <div class="slide-img-videos mb-2">
          <SlidingImagesVideos
            :isProductOos="state.isProductOos"
            :isSingleVariant="state.item?.is_single_variant"
            :image="state.item?.image"
            :itemsData="state.item"
            :imagePath="IMAGE_PATH.PRODUCT"
            :imageDefault="DEFAULT_PRODUCT_IMAGE"
            :selectedVariant="state.specsSelected"
            :productMedia="state.item.attributes?.mediaAttributes"
            :skuMedia="state.skusSelected?.attributes?.mediaAttributes"
          />
        </div>
        <ion-grid class="px-4 pb-2">
          <ion-row>
            <ion-col size="12" class="ion-no-padding">
              <ion-label class="label-tenant">{{ state.item?.tenant?.tenant?.name }}</ion-label>
            </ion-col>
            <ion-col size="12" class="ion-no-padding my-1">
              <ion-label class="label-product-name" ref="refNameProduct">{{ state?.item?.name }}</ion-label>
            </ion-col>
            <ion-col size="12" class="ion-no-padding">
              <ion-label v-if="state.item?.halal" class="label-halal">{{ $t('sync_state.halal') }}</ion-label>
              <ion-label v-if="state.item?.halal !== null && !state.item?.halal" class="label-non-halal">{{
                $t('sync_state.non_halal')
              }}</ion-label>
            </ion-col>
            <ion-col size="12" class="ion-no-padding">
              <div
                v-if="!needQuotationRequest && allowAttach && !showBookingId && displayPriceInfo"
                class="d-flex flex-column"
              >
                <ion-label
                  v-if="!isAllSkusOrderByWeight && !state.skusSelected?.is_order_by_weight"
                  class="label-price"
                  color="primary"
                  >{{ price }}</ion-label
                >
                <ion-label
                  class="label-kg"
                  :class="isAllSkusOrderByWeight || state.skusSelected?.is_order_by_weight ? 'mt-2' : 'mb-1'"
                >
                  {{ price !== `${currencySymbol} NaN` && price ? priceKgBelowTotalPrice : '' }}
                </ion-label>
                <ion-text
                  v-if="isAllSkusOrderByWeight || state.skusSelected?.is_order_by_weight"
                  class="order-by-weight"
                  >{{ $t('order_by_weight') }}</ion-text
                >
              </div>
              <div v-else-if="!allowAttach && showPriceInfo" class="d-flex flex-column">
                <ion-label class="label-price" color="primary">
                  {{ priceDiscountUomFn(state.item, currencySymbol) }}</ion-label
                >
                <ion-label class="label-kg mb-1">
                  {{
                    price !== `${currencySymbol} NaN` && price
                      ? setTotalPricePerUomFn(state.item, currencySymbol, isCalculateByWeight, fromText)
                      : ''
                  }}
                </ion-label>
              </div>
            </ion-col>
          </ion-row>
        </ion-grid>
        <div class="bg-gray"></div>
        <ion-grid class="px-4">
          <ion-row>
            <ion-col size="4" class="ion-no-padding">
              <ion-label class="label-pack" color="grey" v-if="!state.item.is_single_variant">{{
                $t('product_detail.select_variation')
              }}</ion-label>
              <div>
                <ion-badge
                  slot="start"
                  class=""
                  color="primary"
                  fill="outline"
                  v-if="
                    state.item.is_single_variant &&
                    state.item.skusData[0]?.is_purchased &&
                    !state.item.skusData[0]?.is_out_of_stock
                  "
                  >{{ $t('product_detail.buy_again') }}</ion-badge
                >
              </div>
            </ion-col>
            <ion-col v-if="isShowRemaining" size="8" class="ion-no-padding d-flex flex-column align-end">
              <ion-text v-if="!state.skusSelected.is_order_by_weight" class="qty-label" color="danger">
                {{ $t('quantity') }}:
                {{ $t('last_remaming_item', { remainingQuantity }) }}
              </ion-text>
              <ion-text v-else class="qty-label" color="danger">
                {{ $t('quantity') }}:
                {{ $t('last_remaming_weight_item', { remainingQuantity }) }}
              </ion-text>
            </ion-col>
            <ion-col
              class="ion-no-padding d-flex flex-column align-end"
              v-if="state.item.is_single_variant && state.item.skusData[0]?.is_out_of_stock"
            >
              <ion-row>
                <ion-text class="qty-label"> {{ $t('product_detail.stock') }}: </ion-text>
                <ion-text class="qty-label ml-2" color="danger">
                  {{ $t('product_detail.out_of_stock') }}
                </ion-text>
              </ion-row>
            </ion-col>
          </ion-row>
          <ion-row class="mt-2" v-if="!state.item.is_single_variant">
            <ion-col
              class="ion-no-padding mt-1"
              size="12"
              v-for="(specs, keyName) in getSpecsByKey"
              :key="keyName"
            >
              <ion-row>
                <ion-label class="label-keyname">{{ keyName }}</ion-label>
                <ion-label v-if="state.isOutOfStock && allowAttach" class="oos-marker">{{
                  $t('out_of_stock')
                }}</ion-label>
              </ion-row>
              <ion-label class="label-select-another">{{
                state.skusSelected &&
                state.skusSelected.sku_id !== 0 &&
                JSON.stringify(state.skusSelected?.specs).includes(`${keyName}`) &&
                specs.length > 1 &&
                state.isOutOfStock
                  ? ' ' + $t('select_another_option')
                  : ''
              }}</ion-label>
              <div class="d-flex flex-wrap mt-1">
                <div v-for="spec in specs" :key="spec.value" class="form-btn">
                  <ion-badge
                    slot="start"
                    class="oos-badge is-oos"
                    color="medium"
                    v-if="showOosAndBuyAgainStatus(spec.value).showOos"
                    >{{ $t('product_detail.sold_out') }}</ion-badge
                  >
                  <ion-badge
                    slot="start"
                    class="oos-badge buy-again"
                    color="primary"
                    fill="outline"
                    v-if="showOosAndBuyAgainStatus(spec.value).showBuyAgain"
                    >{{ $t('product_detail.buy_again') }}</ion-badge
                  >

                  <ion-button
                    mode="ios"
                    class="variant-chip btn-disable mt-2"
                    v-if="showOosAndBuyAgainStatus(spec.value).showOos"
                    fill="outline"
                    disabled
                  >
                    {{ spec.value }}
                  </ion-button>
                  <ion-button
                    mode="ios"
                    v-else
                    fill="outline"
                    class="variant-chip mt-2"
                    :class="state.specsSelected.includes(spec.value) ? 'btn-primary' : 'btn-grey'"
                    @click="checkSpecItem(spec.value, keyName)"
                    :disabled="state.specsSelected.length > 0 && !isAnySelectedIdIncluded(spec.ids)"
                  >
                    {{ spec.value }}
                  </ion-button>
                </div>
              </div>
            </ion-col>
          </ion-row>
        </ion-grid>

        <div class="bg-gray"></div>
        <ion-grid class="px-4">
          <ion-row>
            <ion-col size="6" class="ion-no-padding">
              <ion-label class="label-pack">{{ $t('packaging_size') }}</ion-label>
              <ion-label class="label-weight mt-1"> {{ packagingSize ? packagingSize : '-' }}</ion-label>
            </ion-col>
            <ion-col size="6" class="ion-no-padding">
              <ion-label class="label-pack">{{ $t('weight') }}</ion-label>
              <ion-text
                v-if="isAllSkusOrderByWeight || state.skusSelected?.is_order_by_weight"
                class="order-by-weight mt-1"
                >{{ $t('order_by_weight') }}</ion-text
              >
              <ion-label v-else class="label-weight mt-1">{{ weight ? weight : '-' }}</ion-label>
              <tooltip v-if="state.skusSelected?.is_catch_weight" />
            </ion-col>
            <ion-col size="12" class="mt-2 ion-no-padding">
              <ion-label class="label-pack">{{ $t('description') }}</ion-label>
              <ion-label ref="refDescription" :class="['label-weight', state.isShowMore ? 'overflow' : '']"
                >{{ state.item?.description ?? '' }}
              </ion-label>
              <ion-label
                v-if="state.isDisplayShowMore"
                @click="state.isShowMore = !state.isShowMore"
                class="label-showmore"
                >{{ state.isShowMore ? $t('show_more') : $t('show_less') }}</ion-label
              >
            </ion-col>
            <ion-col size="6" class="ion-no-padding mt-2" v-for="key in mappedTaggingSkusData" :key="key">
              <div class="d-flex flex-row align-end align-content-end">
                <ion-label class="label-pack mr-1">{{ key.key }}</ion-label>
                <ion-icon
                  v-if="key.key === 'Glazing'"
                  @click="setOpenAttributesInfo(true)"
                  :icon="informationCircleOutline"
                  class="info-icon"
                ></ion-icon>
                <ion-toast
                  :is-open="isOpenAttributesInfo"
                  :duration="3000"
                  :icon="informationCircleOutline"
                  :message="$t('glazing_desc')"
                  mode="ios"
                  color="dark"
                  position="middle"
                  :buttons="okButtons"
                  @didDismiss="setOpenAttributesInfo(false)"
                ></ion-toast>
              </div>
              <ion-label class="label-weight"> {{ key.value ? key.value : '-' }}</ion-label>
            </ion-col>
            <ion-col size="12" class="mt-2 ion-no-padding">
              <ion-label class="label-pack">{{ $t('category_sub') }}</ion-label>
              <div class="form-category my-1 mb-2">
                <ion-label class="label-category-main">{{
                  state.item?.categories?.main_categories?.name
                }}</ion-label>
                <ion-label class="label-category-sub ml-1">{{
                  state.item?.categories?.sub_categories?.name
                }}</ion-label>
              </div>
            </ion-col>
          </ion-row>
        </ion-grid>
        <div class="bg-gray"></div>
        <ion-grid class="pr-4 pl-2 py-2" v-if="!state.item?.booking_order_id">
          <ion-row class="ion-align-items-start">
            <ion-col size="1">
              <ion-icon
                style="font-size: 24px"
                color="primary"
                :icon="notificationsOutline"
                class="header-icon"
              ></ion-icon>
            </ion-col>
            <ion-col size="9">
              <div class="pb-1">
                <ion-label class="fw-700 fs-2">{{ $t('product_stock_update_label') }} <br /></ion-label>
              </div>
              <ion-text class="fs-2 text-gray-700" style="line-height: 1.5">{{
                $t('product_stock_update_desc')
              }}</ion-text>
            </ion-col>
            <ion-col size="2" class="p-0">
              <ion-toggle
                ref="toggleRef"
                v-model="isSubscribed"
                @ionChange="onToggleSubscribe($event)"
              ></ion-toggle>
            </ion-col>
          </ion-row>
        </ion-grid>
        <div
          class="bg-gray"
          v-if="
            (state.item.is_single_variant &&
              (state.item.skusData[0]?.is_out_of_stock || state.item.skusData[0]?.is_purchased)) ||
            isShowRemaining
          "
        ></div>
        <div ref="similiarProduct" class="similiar-product-bg">
          <product-recommend-list
            v-if="(showSimiliarProduct || state.isProductOos) && similiarProductItems?.length > 0"
            :productItems="getSimiliarProductsData"
            :user="state.user"
            @select-similar-product="onSelectSimilarProduct"
            @explore-other-product="onExploreOtherProducts(state?.item?.categories?.main_categories?.id)"
          ></product-recommend-list>
        </div>
      </div>
    </ion-content>

    <ion-footer v-if="state.isOnline" class="ion-no-border footer">
      <ion-toolbar class="ion-no-padding">
        <div class="ml-2">
          <div v-if="needQuotationRequest && !state.isOutOfStock" class="d-flex align-center">
            <div class="flex-grow-1">
              <div v-if="state.skusSelected?.quotation_status === QUOTATION_STATUS.REQUESTED">
                <div class="btn-requested mr-0">
                  <ion-icon :icon="paperPlaneOutline" class="icon"></ion-icon>
                  <span class="ml-1">{{ $t('requested') }}</span>
                </div>
              </div>
              <div v-else>
                <btn-request-quotation
                  :allowAttach="allowAttach"
                  :selectedCompanyId="state.selectedCompany.id"
                  :tenantId="state.item.tenant_id"
                  :userId="state.user.id"
                  :skuId="state.skusSelected?.sku_id"
                  :customer="state.selectedCompany.name || state.selectedCompany.branch"
                  @update-local-request-quotation="updateLocalRequestQuotation"
                />
              </div>
            </div>

            <!-- favorite -->
            <div>
              <btn-favorite
                :isFavorite="state.skusSelected?.is_favorite ?? false"
                :selectedCompany="state.selectedCompany"
                :skusSelected="state.skusSelected"
                :allowAttach="allowAttach"
                @update-local-favorite="updateLocalFavorite"
              />
            </div>
          </div>

          <!-- no needQuotationRequest -->
          <div v-else>
            <ion-grid class="ion-no-padding">
              <ion-row class="footer-input" :class="{ 'mb-3': !isDisableAddButton }">
                <ion-col size="6" class="pl-4 mt-2 mb-1" v-if="showBookingId">
                  <div v-if="showBookingId" class="d-flex flex-column">
                    <ion-label> {{ $t('booking_date') }}: </ion-label>
                    <ion-label class="booking-date mt-2">
                      {{ bookingDateString }}
                    </ion-label>
                    <ion-label class="booking-id mt-2">
                      {{ $t('booking') }}: {{ state.item?.booking_order_id }}
                    </ion-label>
                  </div>
                </ion-col>
                <ion-col size="6" class="pl-4 mt-2 mb-1" v-if="!showBookingId">
                  <div class="d-flex align-center mb-2">
                    <ion-label class="label-total-price">{{ $t('total_price') }}</ion-label>
                    <ion-label class="label-special-price" v-if="isSpecialPrice">
                      {{ $t('special_price') }}</ion-label
                    >
                  </div>
                  <div>
                    <ion-label
                      v-if="!state.isOutOfStock && allowAttach && displayPriceInfo"
                      class="label-sum-price"
                    >
                      <span v-if="state.skusSelected?.is_order_by_weight">
                        {{ showPriceWeight }}
                      </span>
                      <span v-else>
                        {{ showPriceQuantity }}
                      </span>
                    </ion-label>
                    <ion-label v-else class="label-sum-price">-</ion-label>
                  </div>
                  <div>
                    <ion-label
                      v-if="!state.isOutOfStock && allowAttach && displayPriceInfo"
                      class="label-price-kg"
                      >{{ priceKgBelowTotalPrice }}</ion-label
                    >
                    <ion-label v-else class="label-price-kg">-</ion-label>
                  </div>
                </ion-col>
                <ion-col size="6" class="d-flex flex-column align-items-center pr-4 mb-1 ion-no-padding">
                  <div
                    class="d-flex justify-end"
                    v-if="isBargainPrice && !showBookingId && allowAttach && displayPriceInfo"
                  >
                    <ion-button
                      @click="toggleModalBargainPrice"
                      fill="outline"
                      size="small"
                      class="btn-bergain-price"
                      :color="isPendingApprove ? 'medium' : ''"
                      :disabled="isPendingApprove"
                    >
                      {{ isPendingApprove ? $t('pending_approve') : $t('bargain_price') }}</ion-button
                    >
                  </div>
                  <div v-if="state.skusSelected?.is_order_by_weight" class="d-flex align-self-center mt-2">
                    <ion-input
                      type="number"
                      :readonly="state.isOutOfStock && allowAttach && !displayPriceInfo"
                      class="input-weight"
                      :value="formattedWeight"
                      @ionInput="onInputWeight"
                      onkeypress="return (event.charCode >= 48 && event.charCode <= 57) || (event.target.value.indexOf('.') === -1 && event.charCode === 46)"
                    />
                    <span class="ml-1 mt-2">{{ $t('kg') }}</span>
                  </div>
                  <div class="d-flex cnt-weight h-100 form-input d-flex justify-end" v-else>
                    <div class="align-self-center text-center">
                      <div
                        v-if="
                          (!state.isOutOfStock && allowAttach && displayPriceInfo) ||
                          (!state.isOutOfStock && allowAttach && showBookingId)
                        "
                        class="cnt-icon-q mr-2"
                        @click="minusQuantity"
                      >
                        <ion-icon :icon="remove"></ion-icon>
                      </div>
                      <div v-else class="cnt-icon-q-outline mr-2" @click="minusQuantity">
                        <ion-icon class="icon-q" :icon="removeCircleOutline"></ion-icon>
                      </div>
                    </div>
                    <div
                      v-if="
                        (!state.isOutOfStock && allowAttach && displayPriceInfo) ||
                        (!state.isOutOfStock && allowAttach && showBookingId)
                      "
                      size="5"
                      class="align-self-center text-center"
                    >
                      <input
                        v-model="state.orderQuantity"
                        type="number"
                        maxlength="4"
                        class="input-weight"
                        @input="orderQuantityChange"
                        onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                      />
                    </div>
                    <div v-else size="5" class="align-self-center text-center">
                      <ion-input
                        readonly
                        v-model.number="state.outOfStockQty"
                        type="text"
                        class="input-weight"
                      />
                    </div>
                    <div class="align-self-center text-center">
                      <div
                        v-if="
                          (!state.isOutOfStock && allowAttach && displayPriceInfo) ||
                          (!state.isOutOfStock && allowAttach && showBookingId)
                        "
                        class="ml-2"
                        :class="{
                          'cnt-icon-q-disabled': disabledAddQuantity,
                          'cnt-icon-q': !disabledAddQuantity
                        }"
                        @click="addQuantity"
                      >
                        <ion-icon class="icon-q" :icon="add"></ion-icon>
                      </div>
                      <div v-else class="cnt-icon-q-outline ml-2" @click="addQuantity">
                        <ion-icon :icon="addCircleOutline"></ion-icon>
                      </div>
                    </div>
                  </div>
                </ion-col>
              </ion-row>
              <ion-row>
                <ion-col size="7" />
                <ion-col
                  v-if="isDisableAddButton && allowAttach && displayPriceInfo"
                  size="5"
                  class="ion-no-padding"
                >
                  <ion-text color="danger">{{
                    $t('soldInUnits', { number: state.skusSelected?.increment_qty })
                  }}</ion-text>
                </ion-col>
              </ion-row>
            </ion-grid>
            <div class="d-flex align-center">
              <!-- add to cart -->
              <div class="flex-grow-1 btn-add-to-card">
                <div>
                  <ion-button
                    v-if="showAddCartAndSelectVariation"
                    tabindex="-1"
                    class="btn-add-to-cart mr-0"
                    :fill="showAddCartAndSelectVariation ? 'solid' : 'outline'"
                    :disabled="
                      isDisableAddButton ||
                      isDisabledAddToCartByWeight ||
                      !allowAttach ||
                      disableOrderByWeight ||
                      (checkZeroPrice && !allowAddZeroPrice)
                    "
                    :color="!colorGrayBtn ? 'primary' : 'medium'"
                    expand="block"
                    @click="handleCheckLatetestPrice"
                  >
                    <span class="ml-1" v-if="!state.isAddToCartLoading">
                      {{ allowAttach ? $t('add_to_cart') : $t('select_variation') }}
                    </span>
                    <ion-spinner name="crescent" v-else></ion-spinner>
                  </ion-button>
                  <!-- contact to purchase -->
                  <ion-button
                    v-if="showContactToPurchase"
                    tabindex="-1"
                    class="btn-add-to-cart mr-0"
                    fill="solid"
                    :color="state.specsSelected.length !== 0 ? 'primary' : 'medium'"
                    expand="block"
                    :disabled="state.specsSelected.length !== 0 ? false : true"
                    @click="handleContactToPurchase"
                  >
                    <ion-icon
                      v-if="state.specsSelected.length !== 0"
                      :icon="chatbubbleEllipsesOutline"
                      class="icon"
                    ></ion-icon>
                    <span class="ml-1">
                      {{
                        state.specsSelected.length !== 0 ? $t('contact_to_purchase') : $t('select_variation')
                      }}
                    </span>
                  </ion-button>

                  <!-- Out of stock button -->
                  <ion-button
                    v-if="showOosAndSelectOtherVariant"
                    tabindex="-1"
                    class="btn-add-to-cart mr-0"
                    fill="solid"
                    :disabled="true"
                    color="medium"
                    expand="block"
                  >
                    <span v-if="!checkOtherVariation" class="ml-1"> {{ $t('out_of_stock') }}</span>
                    <ion-spinner v-else name="dots"></ion-spinner>
                  </ion-button>
                  <div v-if="showQuotation && !showOosAndSelectOtherVariant">
                    <div v-if="state.skusSelected?.quotation_status === QUOTATION_STATUS.REQUESTED">
                      <div class="btn-requested mr-0">
                        <ion-icon :icon="paperPlaneOutline" class="icon"></ion-icon>
                        <span class="ml-1">{{ $t('requested') }}</span>
                      </div>
                    </div>
                    <btn-request-quotation
                      v-else
                      :allowAttach="allowAttach"
                      :selectedCompanyId="state.selectedCompany.id"
                      :tenantId="state.item.tenant_id"
                      :userId="state.user.id"
                      :skuId="state.skusSelected?.sku_id"
                      :customer="state.selectedCompany.name || state.selectedCompany.branch"
                      @update-local-request-quotation="updateLocalRequestQuotation"
                    />
                  </div>
                </div>
              </div>

              <!-- favorite -->
              <div>
                <btn-favorite
                  :isFavorite="state.skusSelected?.is_favorite ?? false"
                  :selectedCompany="state.selectedCompany"
                  :skusSelected="state.skusSelected"
                  :allowAttach="allowAttach"
                  @update-local-favorite="updateLocalFavorite"
                />
              </div>
            </div>
          </div>
        </div>
      </ion-toolbar>
    </ion-footer>
    <ion-modal
      ref="modal"
      :initial-breakpoint="1"
      :breakpoints="[0, 0.25, 0.5, 0.75, 1]"
      mode="ios"
      css-class="default-bottom-sheet-modal"
      :is-open="state.isOpenModalBargainPrice"
      @didDismiss="state.isOpenModalBargainPrice = false"
    >
      <modal-bargain-price
        :packaging_size="packaging_size"
        :weight="weight"
        :item="state.item"
        :skusSelected="state.skusSelected"
        :directPrice="directPrice"
        :directPriceAboveKg="directPriceAboveKg"
        :selectedCompanyId="state.selectedCompany.id"
        :tenantId="state.item.tenant_id"
        :userId="state.user.id"
        :skuId="state.skusSelected?.sku_id"
        :customer="state.selectedCompany.name || state.selectedCompany.branch"
        @offModal="state.isOpenModalBargainPrice = false"
        @handlePendingApprove="handlePendingApprove"
      />
    </ion-modal>
    <ion-modal
      ref="modal-other-variant"
      :initial-breakpoint="1"
      :breakpoints="[0, 0.25, 0.5, 0.75, 1]"
      mode="ios"
      css-class="modal-instruction"
      :is-open="state.isOpenSelectOtherVariant"
      @didDismiss="setOtherVariantModal(false)"
    >
      <modal-other-variant
        @closeSelectOtherVariantModal="setOtherVariantModal(false)"
        :getSpecsOosByKey="getSpecsOosByKey"
        :getSpecsOosById="getSpecsOosById"
        :selectedCompany="state.selectedCompany"
        :user="state.user"
        :oosList="oosList"
        :currencySymbol="currencySymbol"
        :item="state.item"
        @updateSkusfavorite="changeSkuFavorite"
        @refreshFavoriteList="$emit('refresh-favorite-list')"
      />
    </ion-modal>
    <ion-modal
      mode="ios"
      backdrop-dismiss="false"
      :is-open="isOpenPriceUpdated"
      css-class="modal-price-updated"
      @didDismiss="setOpenModalPriceUpdate(false)"
    >
      <price-update-alert @close-modal="setOpenModalPriceUpdate(false)"></price-update-alert>
    </ion-modal>
  </ion-page>
</template>
<script>
import { apolloClient } from '@/main';
import {
  DEFAULT_PRODUCT_IMAGE,
  IMAGE_PATH,
  JOIN_STATUS,
  NOTIFICATION_TYPE,
  QUOTATION_STATUS,
  TYPE_SEARCH,
  USER_TYPE
} from '@/modules/b2b/constants';
import { displayPrice, getTomorrow } from '@/modules/b2b/services/libs/helper';
import { priceDiscountUomFn, priceFn, setTotalPricePerUomFn } from '@/modules/b2b/services/libs/home';
import { MUTATIONS as MUTATIONS_CATEGORY } from '@/modules/b2b/store/category/mutations';
import { ACTIONS as ACTIONS_B2B, ACTIONS as ACTION_OOS } from '@/modules/b2b/store/product/actions';
import { MUTATIONS } from '@/modules/b2b/store/product/mutations';
import {
  CATEGORY_ENABLE_OOS,
  DEFAULT_WEIGHT_UOM,
  INPUT_QUANTITY_BY_WEIGHT,
  LIMIT_PRODUCT_REMAINING,
  MAX_INPUT,
  QUOTED_PRICED_BY
} from '@/modules/shared/constants/';
import { isLargerMaxInput, isQuantityAMultipleOfIncrement, useCheck } from '@/modules/shared/utils/';
import CleverTap from '@/services/shared/helper/clevertap';
import { priceFormatter } from '@/utils/';
import {
  alertController,
  isPlatform,
  onIonViewDidEnter,
  onIonViewWillLeave,
  toastController,
  useBackButton
} from '@ionic/vue';
import {
  add,
  chatbubbleEllipsesOutline,
  heart,
  informationCircleOutline,
  notificationsOutline,
  paperPlaneOutline,
  remove
} from 'ionicons/icons';
import debounce from 'lodash.debounce';
import { computed, defineAsyncComponent, defineComponent, inject, reactive, ref, watch } from 'vue';
import functions from './utils';

// components
import Tooltip from '@/components/molecules/tooltip/Tooltip.vue';
import SlidingImagesVideos from '@/components/sliding-images-videos';
import {
  getCustomerDetailBuyer,
  getLatestPrice,
  getSupplierContactInfo
} from '@/modules/b2b/services/graphql';
import PriceUpdateAlert from '../cart/components/PriceUpdateAlert.vue';
import ProductDetailSkeleton from './components/Skeleton.vue';
import ModalBargainPrice from './components/modals/BargainPrice.vue';
import ModalOtherVariant from './components/modals/OtherVariant.vue';
import ProductRecommendList from './components/recommend-product/RecommendedList.vue';
import BtnFavorite from './partials/BtnFavorite.vue';
import BtnRequestQuotation from './partials/BtnRequestQuotation.vue';
// vuex
import { ACTIONS as ACTIONS_CART } from '@/modules/b2b/store/cart/actions';
import { setBadgeNumber } from '@/modules/shared/utils/badge';
import { useProductSubscribeNotification } from '@/usecases/product/useProductSubscribeNotification';
import { Capacitor } from '@capacitor/core';
import { Device } from '@capacitor/device';
import { Share } from '@capacitor/share';
import dayjs from 'dayjs';
import {
  addCircleOutline,
  arrowDownOutline,
  checkmarkOutline,
  chevronBackOutline,
  closeOutline,
  removeCircleOutline,
  shareSocialOutline,
  storefrontOutline
} from 'ionicons/icons';
import { useI18n } from 'vue-i18n';
import { useRoute, useRouter } from 'vue-router';
import { useStore } from 'vuex';

export default defineComponent({
  name: 'ProductDetail',
  components: {
    Tooltip,
    BtnFavorite,
    BtnRequestQuotation,
    ProductDetailSkeleton,
    ModalBargainPrice,
    ModalOtherVariant,
    ProductRecommendList,
    PriceUpdateAlert,
    SlidingImagesVideos,
    PullRefresh: defineAsyncComponent(() => import('@/modules/shared/views/refreshPage/PullRefresh.vue'))
  },
  setup() {
    onIonViewDidEnter(async () => {
      await clearQueryProductDetail();
      await getProductDetailData();
    });
    onIonViewWillLeave(() => {
      if (showSimiliarProduct.value) {
        similiarProductItems.value = null;
      }
    });
    const storage = inject('$storage');
    const store = useStore();
    const state = reactive({
      user: null,
      buyer: null,
      tenantClassLevel: null,
      specsSelected: [],
      specsInvolved: [],
      skuIdsInvolved: [],
      skusSelected: null,
      selectedCompany: null,
      orderQuantity: 1,
      outOfStockQty: 0,
      isOnline: true,
      orderWeight: null,
      isOutOfStock: false,
      isViewOnly: false,
      isProductOos: false,
      isShow: false,
      isShowHeader: false,
      isShowMore: true,
      isDisplayShowMore: false,
      item: null,
      taggingSkuAttributes: null,
      selectedTab: '',
      isOpenSelectOtherVariant: false,
      isAddToCartLoading: false,
      isChangeSkuFavorite: false,
      isOpenModalBargainPrice: false,
      oosVariable: {
        mainCategoryId: '',
        isSingleVariant: false,
        productId: null,
        tenantId: null,
        buyerId: null,
        limit: 5,
        offset: 0
      },
      multiSelectedSpecs: [],
      selectedSkuIds: []
    });
    const route = useRoute();
    const router = useRouter();
    const { t } = useI18n();
    const reRenderProductImage = ref(1);
    const checkOtherVariation = ref(false);
    const similiarProductItems = ref(null);
    const contactToPurchase = ref(null);
    const showPriceInfo = ref(false);
    const opacity = ref(0);
    const refDescription = ref(null);
    const productDetailContent = ref(null);
    const similiarProduct = ref(null);
    const fromText = ref(t('orderB2b.from'));

    // Actions
    const updateSearch = async (params) =>
      await store.dispatch('b2b/product/' + ACTIONS_B2B.UPDATE_SEARCH, params);
    const getProductRecommend = async (params) =>
      await store.dispatch('b2b/product/' + ACTION_OOS.GET_BUYER_PRODUCTS_RECOMMEND, params);
    const resetProductRecommend = async () =>
      await store.dispatch('b2b/product/' + ACTION_OOS.RESET_PRODUCT_RECOMMEND);
    const oosUpdateSearch = async (params) =>
      await store.dispatch('b2b/product/' + ACTION_OOS.UPDATE_SEARCH, params);
    const getCartItems = async (params) =>
      await store.dispatch('b2b/cart/' + ACTIONS_CART.GET_CART_ITEMS, params);
    const addItemToCart = async (params) =>
      await store.dispatch('b2b/cart/' + ACTIONS_CART.ADD_ITEM_TO_CART, params);

    // Mutations
    const resetCategory = async () => await store.commit('b2b/product/' + MUTATIONS.RESET_CATEGORY);
    const setProductIdAfterExplore = async (params) =>
      await store.commit('b2b/product/' + MUTATIONS.SET_PRODUCT_ID_AFTER_EXPLORE, params);
    const exploreRelatedCategories = async (params) =>
      await store.commit('b2b/category/' + MUTATIONS_CATEGORY.EXPLORE_RELATED_CATEGORIES, params);

    // functions
    const toggleModalBargainPrice = () => (state.isOpenModalBargainPrice = !state.isOpenModalBargainPrice);
    const changeSkusSelected = (sku = { sku_id: 0 }) => {
      state.skusSelected = sku;
      if (sku.weight) {
        state.orderWeight = sku.weight.toFixed(2);
        if (!sku.is_back_order && sku.total_available_quantity > 0) {
          state.orderWeight =
            sku.weight > sku.total_available_quantity
              ? sku.total_available_quantity.toFixed(2)
              : sku.weight.toFixed(2);
        }
      } else {
        state.orderWeight = 0;
      }
    };

    const { checkWeightItem, combineTwoUnit, isItemKGAndNoneUnit } = useCheck();
    const openToast = async (message, color = 'primary', position = 'top') => {
      const toast = await toastController.create({
        message,
        position,
        color,
        duration: 2000
      });
      return toast.present();
    };
    const showAlert = async ({ header, message }) => {
      const alert = await alertController.create({
        mode: 'ios',
        header,
        message,
        buttons: [t('close')]
      });
      setTimeout(async () => await alert.present(), 200);
    };

    // contact to purchase
    const handleContactToPurchase = async () => {
      try {
        const { data, errors } = await apolloClient.query({
          query: getSupplierContactInfo,
          variables: {
            customerId: state.selectedCompany.id,
            tenantId: state.user.tenant.id
          }
        });
        if (errors) {
          // Show toast message when no contact number
          openToast(errors[0]?.message, 'danger');
        } else {
          contactToPurchase.value = data.getSupplierContactInfo;
          handleContactNumber(contactToPurchase.value);
        }
      } catch (error) {
        console.log(error);
      }
    };

    const handleContactNumber = (contactData) => {
      const contactNumber = contactData.customerServiceNumber;
      const contactType = contactData.preferredCommunicationChannel;
      const phoneCallURL = `tel:${contactNumber}`;
      const productDetailUrl = `${process.env.VUE_APP_PRODUCT_SHARING_URL}/?url=main/product-detail/${state.item.id}`;
      const whatsappMessage =
        t('i_would_like_to_purchase', {
          sku_name: state.skusSelected?.sku,
          company_name: state.selectedCompany.name || state.selectedCompany.branch
        }) +
        ' ' +
        productDetailUrl;
      const whatsappURL = `https://api.whatsapp.com/send?phone=${contactNumber}&text=${encodeURIComponent(
        whatsappMessage
      )}`;

      if (!contactNumber) {
        openToast(t('contact_supplier_to_purchase'), 'danger');
      } else {
        if (Capacitor.isNativePlatform()) {
          contactType === 'Whatsapp'
            ? (window.location.href = whatsappURL)
            : (window.location.href = phoneCallURL);
        } else {
          contactType === 'Whatsapp'
            ? window.open(whatsappURL, '_blank')
            : (window.location.href = phoneCallURL);
        }
      }
    };

    const detectConnection = () => {
      const connection = navigator.onLine;
      if (connection) {
        state.isOnline = true;
      } else {
        state.isOnline = false;
      }
    };

    const setOrderQuantity = (value) => {
      state.orderQuantity = value;
    };
    const { getProductDetail, clearQueryProductDetail } = functions.useGetProductDetail();
    const changeSkuFavorite = () => {
      state.isChangeSkuFavorite = true;
    };

    const handleRefreshAndRefetchData = async (event) => {
      await handleRefresh(event);
      if (state.isOnline) {
        await getProductDetailData();
      }
    };
    const handleRefresh = async (event) => {
      await detectConnection();
      event.target.complete();
    };

    const getProductDetailData = async () => {
      const stateData = state;
      const oosVar = state.oosVariable;
      stateData.selectedTab = route.query.tab;
      stateData.user = await storage.getUser();
      stateData.buyer = await storage.getSelectedCompany();
      const { data, error } = await getProductDetail({
        productId: +route.params.id,
        tenantId: stateData.user.tenant.id,
        buyerId: stateData.buyer.id
      });
      if (error.value) {
        await router.push({ path: '/b2b/main/product-detail/error' });
        return;
      }
      stateData.item = data.value.getProductDetails;
      getData(stateData.item);
      stateData.taggingSkuAttributes = data.value.getProductAttributesDescription?.attributes;
      await Promise.all([storage.getSelectedCompany()]).then((val) => {
        stateData.selectedCompany = val[0];
      });
      defaultSpecsSelected();
      stateData.isProductOos = stateData.item.skusData.every((sku) => sku.is_out_of_stock);
      stateData.isViewOnly = stateData.item.discoverability;
      if (stateData.skusSelected) {
        stateData.orderWeight = stateData?.skusSelected?.weight?.toFixed(2) ?? ``;
        if (!stateData.is_back_order && stateData.skusSelected.total_available_quantity > 0) {
          stateData.orderWeight =
            stateData.skusSelected.weight > stateData.skusSelected.total_available_quantity
              ? stateData.skusSelected.total_available_quantity.toFixed(2)
              : stateData.skusSelected.weight.toFixed(2);
        }
        stateData.isOutOfStock = stateData.skusSelected.is_out_of_stock;
      } else {
        stateData.orderWeight = 0;
      }
      stateData.isShow = true;

      oosVar.mainCategoryId = search.value.mainCategoryId ? search.value.mainCategoryId : 'history';
      oosVar.isSingleVariant = stateData.item.is_single_variant;
      oosVar.productId = stateData.item.id;
      oosVar.tenantId = stateData.item.tenant_id;
      oosVar.buyerId = stateData.selectedCompany.id;
      if (stateData.isProductOos) {
        await getProductRecommend(oosVar);
      }
      await handleDisplayShowMore();
      showPriceInfo.value = stateData.item.skusData.some((item) => {
        return item.price > 0 && item.quotation_status === 'received';
      });
    };

    const backToPreviousPage = () => {
      if (productIdAfterExplore.value) {
        router.replace(`/b2b/main/product-detail/${productIdAfterExplore.value}`);
        setProductIdAfterExplore(null);
      } else {
        router.back();
      }
    };

    const handleCheckLatetestPrice = async () => {
      await checkGetLatestPrice();
      await addToCart();
    };

    const addToCart = async () => {
      const historyState = window.history.state;
      const prevHistoryPath = historyState?.back;
      CleverTap.onUserAddToCart({
        data: state.item,
        sku: state.skusSelected,
        orderQty: state.orderQuantity
      });
      if (latesNewPrice.value && state.skusSelected.price !== latesNewPrice.value[0].price) {
        setOpenModalPriceUpdate(true);
      } else {
        state.isAddToCartLoading = true;
        const cartData =
          cart.value && cart.value.items.length !== 0
            ? cart.value
            : {
                items: [],
                delivery_date: getTomorrow(),
                delivery_time: '',
                description: '',
                po_number: '',
                standalone: 0
              };
        const item = {
          sku_id: state.skusSelected.sku_id,
          is_favorite: state.skusSelected.is_favorite,
          quotation_status: state.skusSelected.quotation_status,
          supplier_id: state.selectedCompany.id,
          order_qty: state.skusSelected.is_order_by_weight
            ? INPUT_QUANTITY_BY_WEIGHT
            : parseInt(state.orderQuantity),
          order_weight: state.orderWeight,
          selected: true
        };

        const params = {
          customer_id: state.selectedCompany.id,
          delivery_date: cartData.delivery_date,
          delivery_time: cartData.delivery_time,
          description: cartData.description,
          po_number: cartData.po_number,
          standalone: cartData.standalone === 1 ? true : false,
          item
        };
        await addItemToCart(params);
        await setBadgeNumber(store, state.selectedCompany.id);

        openToast(t('successfully_added_to_cart'));
        if (state.isOpenSelectOtherVariant) {
          state.isOpenSelectOtherVariant = false;
        }
        state.isAddToCartLoading = false;
        await setProductIdAfterExplore(null);
        if (!prevHistoryPath) router.replace('/b2b/main/home');
        else router.back();
      }
    };

    const showAlertErrPrice = async () => {
      const alert = await alertController.create({
        header: 'Oops!',
        mode: 'ios',
        message: t('something_went_wrong_please_try_again'),
        buttons: [
          {
            text: t('OK'),
            handler: async () => {
              await detectConnection();
              await handleGetCustomerDetail();
            }
          }
        ]
      });
      await alert.present();
    };
    const handleGetCustomerDetail = async () => {
      try {
        const { data } = await apolloClient.query({
          query: getCustomerDetailBuyer,
          variables: {
            id: state.selectedCompany.id
          }
        });
        await storage.setSelectedCompany({
          ...state.selectedCompany,
          account_number: data.getCustomerDetailBuyer.account_number
        });
        const updateCompany = await storage.getSelectedCompany();
        state.selectedCompany = updateCompany;
      } catch (error) {
        console.log(error);
      }
    };

    //Check latest items/product price
    const checkGetLatestPrice = async () => {
      try {
        const { data } = await apolloClient.query({
          query: getLatestPrice,
          variables: {
            skuIds: state.skusSelected.sku_id,
            accountNumber: state.selectedCompany.account_number
          }
        });
        latesNewPrice.value = data.getLatestPrice;
      } catch (error) {
        showAlertErrPrice();
      }
    };

    //show price changes alert
    const setOpenModalPriceUpdate = async (params) => {
      isOpenPriceUpdated.value = params;
      if (!params) {
        await getProductDetailData();
        await updateSkusSelectedById(state.skusSelected.sku_id);
      }
    };

    const defaultSpecsSelected = () => {
      if (state.item.skusData.length === 1 && !state.item.skusData[0].specs) {
        changeSkusSelected({ ...state.item.skusData[0], image: state.item.image });
        const parseSpecs = { Single_Variant: true };
        state.specsSelected = Object.values(parseSpecs);
        state.specsInvolved = Object.values(parseSpecs);
      }
    };
    const handleShowHeader = debounce((event) => {
      event.detail.scrollTop > 270 ? (state.isShowHeader = true) : (state.isShowHeader = false);
    }, 100);

    const handleDisplayShowMore = debounce(() => {
      if (!refDescription.value || null) return;
      // Accessing the scrollHeight property using .value
      refDescription.value.$el.scrollHeight > 48
        ? (state.isDisplayShowMore = true)
        : (state.isDisplayShowMore = false);
    }, 500);

    const onInputWeight = (event) => {
      const maxWeight = 999999.99;
      let value = event.target.value;
      const decimalIndex = value.indexOf('.');
      if (decimalIndex !== -1 && value.slice(decimalIndex + 1).length > 2) {
        // truncate to two decimal places
        value = Math.trunc(parseFloat(value) * 100) / 100;
        event.target.value = value;
      }
      state.orderWeight = value;
      if (!isBackOrder.value && state.orderWeight > remainingQuantity.value) {
        state.orderWeight = remainingQuantity.value.toFixed(2);
        event.target.value = state.orderWeight;
        openToast(t('out_of_max_weight', { weight: remainingQuantity.value.toFixed(2) }), 'danger');
      } else {
        if (formattedWeight.value > maxWeight) {
          openToast(t('out_of_max_weight', { weight: maxWeight }), 'danger');
          state.orderWeight = maxWeight.toFixed(2);
          event.target.value = state.orderWeight;
        }
      }
    };
    const minusQuantity = () => {
      if (state.isOutOfStock || (!allowAttach.value && !displayPriceInfo.value)) {
        return;
      }
      const quantity = +state.orderQuantity || 0;
      const increment = +state.skusSelected?.increment_qty;
      if (quantity <= increment) {
        state.orderQuantity = increment;
      } else if (
        isQuantityAMultipleOfIncrement({
          quantity: quantity,
          increment: increment
        })
      ) {
        state.orderQuantity -= increment;
      } else {
        state.orderQuantity -= quantity % increment;
      }
    };
    const addQuantity = () => {
      if (state.isOutOfStock || (!allowAttach.value && !displayPriceInfo.value)) {
        return;
      }
      const quantity = +state.orderQuantity || 0;
      const increment = +state.skusSelected?.increment_qty;
      if (isLargerMaxInput(quantity + increment)) {
        state.orderQuantity = MAX_INPUT;
      } else {
        if (!isBackOrder.value) {
          if (quantity < remainingQuantity.value) {
            state.orderQuantity = quantity + increment - (quantity % increment);
          } else {
            openToast(t('out_of_max_quantity', { quantity: remainingQuantity.value }), 'danger');
            state.orderQuantity = remainingQuantity.value - (remainingQuantity.value % increment);
          }
        } else {
          state.orderQuantity = quantity + increment - (quantity % increment);
        }
      }
    };
    const orderQuantityChange = () => {
      // process check input value
      if (!state.orderQuantity) return;

      state.orderQuantity = Number(`${state.orderQuantity}`.replace(/[^\d]/g, '')) || 0;

      if (isLargerMaxInput(state.orderQuantity)) {
        state.orderQuantity = MAX_INPUT;
      } else {
        if (!isBackOrder.value && state.orderQuantity > remainingQuantity.value) {
          const increment = Number(state.skusSelected.increment_qty);
          openToast(t('out_of_max_quantity', { quantity: remainingQuantity.value }), 'danger');
          state.orderQuantity = remainingQuantity.value - (remainingQuantity.value % increment);
        }
      }
      state.orderQuantity = `${state.orderQuantity}`;
    };

    const updateSkusSelectedById = (id) => {
      if (!id) {
        changeSkusSelected();
      } else {
        const index = state.item?.skusData.findIndex((skus) => skus.sku_id == id);
        let data = {};
        if (index !== -1) {
          data = {
            ...state.item?.skusData[index],
            image: state.item?.image
          };
        }
        changeSkusSelected(data);
      }
    };
    const updateLocalRequestQuotation = async () => {
      if (error.value) {
        await showAlert({
          header: t('quotationPendingTitle'),
          message: error.value.message
        });
        return;
      }
      changeSkusSelected({
        ...state.skusSelected,
        quotation_status: QUOTATION_STATUS.REQUESTED
      });
      const index = state.item?.skusData.findIndex((skus) => skus.sku_id == state.skusSelected.sku_id);
      state.item.skusData[index].quotation_status = QUOTATION_STATUS.REQUESTED;
      await showAlert({
        header: t('success'),
        message: t('quotation_has_been_sent')
      });
    };
    const setOtherVariantModal = async (value) => {
      state.isOpenSelectOtherVariant = value;
      // select other variant modal is not used anymore
      // if (state.isOpenSelectOtherVariant) {
      //   defaultOosSpecsSelected();
      // } else {
      //   defaultSpecsSelected();
      //   checkSpecItem(state.specsSelected[0]);
      // }
    };

    const unCheckSpecItem = async (spec) => {
      const list = getSkusInfo.value;
      const index = state.specsSelected.indexOf(spec);
      if (index !== -1) {
        // remove 1 spec -> length - 1
        state.specsSelected.splice(index, 1);
        const { specsInvolved, skuIdsInvolved } = functions.getSpecsInvolvedBySpecs(
          state.specsSelected,
          list
        );
        state.specsInvolved = specsInvolved;
        state.skuIdsInvolved = skuIdsInvolved;

        // check if specsInvolved lenght = specsSelected length -> clear specsSelected
        if (state.specsSelected.length + 1 === state.specsInvolved.length) {
          state.specsSelected = [];
          const {
            specsInvolved: specsInvolvedTmp,
            skuIdsInvolved: skuIdsInvolvedTmp
          } = functions.getSpecsInvolvedBySpecs(state.specsSelected, list);
          state.specsInvolved = specsInvolvedTmp;
          state.skuIdsInvolved = skuIdsInvolvedTmp;
        }
      }
      updateSkusSelectedById(0);
    };

    const selectSpec = (specCategory, specValue) => {
      const existingSpecIndex = state.multiSelectedSpecs.findIndex((spec) => spec.category === specCategory);
      if (existingSpecIndex !== -1) {
        if (state.multiSelectedSpecs[existingSpecIndex].value !== specValue) {
          state.multiSelectedSpecs[existingSpecIndex] = { category: specCategory, value: specValue };
        } else if (state.multiSelectedSpecs[existingSpecIndex].value === specValue) {
          state.multiSelectedSpecs.splice(existingSpecIndex, 1);
          state.specsInvolved = state.specsInvolved.filter((item) => item !== specValue);
          state.skuIdsInvolved = [];
          state.skusSelected = null;
        }
      } else {
        state.multiSelectedSpecs.push({ category: specCategory, value: specValue });
      }
      return state.multiSelectedSpecs;
    };

    const checkSpecItem = async (value, specCategory) => {
      const variantCount = Object.keys(getSpecsByKey.value).length;
      await selectSpec(specCategory, value);
      const specs = state.multiSelectedSpecs.map((spec) => spec.value);
      const list = getSkusInfo.value;
      const { skuIds, specsSelected } = functions.findSkuFromSpecs(specs, list);
      state.selectedSkuIds =
        variantCount > 1
          ? getAllowedSelectedSkusBySpecs(getSkusInfo.value, value)
          : getAllSkuIds(getSkusInfo.value);
      if (skuIds.length === 1) {
        updateSkusSelectedById(skuIds[0]);
        state.specsSelected = specsSelected;
        state.specsInvolved = specsSelected;
        state.skuIdsInvolved = skuIds;
      } else {
        state.specsSelected = specs;
        const {
          specsInvolved: specsInvolvedTmp,
          skuIdsInvolved: skuIdsInvolvedTmp
        } = functions.getSpecsInvolvedBySpecs(state.specsSelected, list);
        state.specsInvolved = specsInvolvedTmp;
        state.skuIdsInvolved = skuIdsInvolvedTmp;
      }
    };

    const getAllSkuIds = (data) => {
      const skuIdsArray = [];
      for (let i = 0; i < data.length; i++) {
        skuIdsArray.push(data[i].skuId);
      }
      return skuIdsArray;
    };

    const checkSpecsListDataBySelectedSpecs = (data, value) => {
      for (let i = 0; i < data.length; i++) {
        if (data[i].specs.includes(value)) {
          return data[i].specs;
        }
      }
      return [];
    };

    const getAllowedSelectedSkusBySpecs = (data, selectedSpecs) => {
      const skuIdsData = [];
      const getAllSpecsListData = checkSpecsListDataBySelectedSpecs(data, selectedSpecs);

      for (let i = 0; i < data.length; i++) {
        const specs = data[i].specs;

        for (let j = 0; j < getAllSpecsListData.length; j++) {
          if (specs.includes(getAllSpecsListData[j])) {
            if (!skuIdsData.includes(data[i].skuId)) {
              skuIdsData.push(data[i].skuId);
            }
          }
        }
      }
      return skuIdsData;
    };

    const isAnySelectedIdIncluded = (selectedIds) => {
      return state.skuIdsInvolved.some((id) => selectedIds.includes(id));
    };

    const showOosAndBuyAgainStatus = (value) => {
      const list = getSkusInfo.value;
      const result = {
        showOos: true,
        showBuyAgain: false
      };

      // check oos status
      if (!state.specsInvolved.length || state.specsInvolved.includes(value)) {
        list.forEach((item) => {
          if (
            (!state.skuIdsInvolved.length || state.skuIdsInvolved.includes(item.skuId)) &&
            item.specs.includes(value) &&
            !item.isOos
          ) {
            result.showOos = false;
          }
          if (
            (!state.skuIdsInvolved.length || state.skuIdsInvolved.includes(item.skuId)) &&
            !result.showOos &&
            item.isPurchased &&
            item.specs.includes(value)
          )
            result.showBuyAgain = true;
        });
      } else {
        result.showOos = false;
      }

      return result;
    };

    const checkNotAvailableSpecs = (spec) => {
      const list = getSkusInfo.value;
      let isOosValue = false;

      for (let i = 0; i < list.length; i++) {
        if (list[i].specs.includes(spec)) {
          isOosValue = list[i].isOos;
          break;
        }
      }
      return isOosValue;
    };

    const checkIsBuyAgainSpecs = (spec) => {
      const list = getSkusInfo.value;
      let isBuyAgain = false;

      for (let i = 0; i < list.length; i++) {
        if (list[i].specs.includes(spec)) {
          isBuyAgain = list[i].isPurchased;
          break;
        }
      }
      return isBuyAgain;
    };

    const scrollToSimiliarProducts = () => {
      const contentElement = productDetailContent.value.$el;
      const similarProductElement = similiarProduct.value;

      if (contentElement && similarProductElement) {
        const targetOffsetTop = similarProductElement.offsetTop - 65;
        const targetOffsetLeft = similarProductElement.offsetLeft;

        contentElement.scrollToPoint(targetOffsetLeft, targetOffsetTop, 500);
      }
    };

    const handlePendingApprove = async () => {
      state.skusSelected.quotation_status = QUOTATION_STATUS.REQUESTED;
      await getProductDetailData();
      await updateSkusSelectedById(state.skusSelected.sku_id);
    };
    const updateLocalFavorite = () => {
      const newFavorite = !state.skusSelected.is_favorite;
      changeSkusSelected({
        ...state.skusSelected,
        is_favorite: newFavorite
      });

      state.item.skusData.forEach((skus) => {
        if (skus.sku_id == state.skusSelected.sku_id) {
          skus.is_favorite = newFavorite;
        }
      });
      state.isChangeSkuFavorite = true;
    };
    const onSelectSimilarProduct = async (item) => {
      CleverTap.onUserViewRecommendProduct(item);
      await setProductIdAfterExplore(state.item.id);
      await resetProductRecommend();

      router.replace({
        path: `/b2b/main/product-detail/${item.id}`
      });
    };

    const onExploreOtherProducts = async (tabCategory) => {
      // save the oos product category that need to explore
      await exploreRelatedCategories(tabCategory.toString());
      state.specsSelected = [];
      state.specsInvolved = [];
      state.skusSelected = null;
      state.isOutOfStock = false;
      router.push({ path: '/b2b/main/home' });
    };
    const openSocialSharing = async () => {
      // only support sharing feature on native device
      if (Capacitor.isNativePlatform()) {
        const deviceInfo = await Device.getInfo();
        CleverTap.onUserShareProduct(state.item);

        if (isPlatform('ios')) {
          // iOs only copy text when we click on copy button by default
          await Share.share({
            title:
              t('product_detail.sharing_title', { product_name: state.item.name }) +
              `\n${process.env.VUE_APP_PRODUCT_SHARING_URL}/?url=main/product-detail/${state.item.id}`,
            text:
              t('product_detail.sharing_text', { product_name: state.item.name }) +
              `\n${process.env.VUE_APP_PRODUCT_SHARING_URL}/?url=main/product-detail/${state.item.id}`,
            url:
              t('product_detail.sharing_text', { product_name: state.item.name }) +
              `\n${process.env.VUE_APP_PRODUCT_SHARING_URL}/?url=main/product-detail/${state.item.id}`,
            dialogTitle: t('product_detail.sharing_dialog_title')
          });
        } else {
          await Share.share({
            title: t('product_detail.sharing_title', { product_name: state.item.name }),
            text: t('product_detail.sharing_text', { product_name: state.item.name }),
            url: `${process.env.VUE_APP_PRODUCT_SHARING_URL}/?url=main/product-detail/${state.item.id}`,
            dialogTitle: t('product_detail.sharing_dialog_title')
          });
        }
        CleverTap.onUserShareProductSuccess(deviceInfo);
      }
    };

    const setSearchParamsBackToDefault = async () => {
      await resetCategory();
      await updateSearch({
        search: {
          ...search.value,
          searchQueries: '',
          mainCategoryId: 'history',
          sortBy: 'purchased',
          sortType: 'desc',
          categories: [],
          offset: 0
        },
        type: TYPE_SEARCH.CATEGORY
      });
    };

    // computed
    const currencySymbol = computed(() => {
      return state.user && state.user.currency_symbol;
    });

    const itemComputed = computed(() => store.getters['b2b/product/item']);
    const search = computed(() => store.getters['b2b/product/search']);
    const error = computed(() => store.getters['b2b/product/error']);
    const productIdAfterExplore = computed(() => store.getters['b2b/product/productIdAfterExplore']);
    const oosList = computed(() => store.getters['b2b/product/oosList']);
    const cart = computed(() => store.getters['b2b/cart/cart']);

    const getSpecsOosByKey = computed(() => {
      return functions.sortListStrings(functions.getSpecsOosByKey(oosList.value?.oosList?.otherVariant));
    });
    const getSpecsOosById = computed(() => {
      return functions.getSpecsOosById(oosList.value?.oosList?.otherVariant);
    });
    const isSample = computed(() => {
      return search.value.isSample;
    });
    const formattedWeight = computed(() => {
      return state.orderWeight;
    });
    const getSimiliarProductsData = computed(() => {
      return similiarProductItems.value;
    });
    const showBookingId = computed(() => {
      return state.item?.booking_order_id && state.item?.booking_order_id > 0;
    });
    const bookingDateString = computed(() => {
      return dayjs(state.item?.booking_order_date).format('DD MMM YYYY');
    });

    const packagingSize = computed(() => {
      if (state.skusSelected) {
        const { unit_amount, uom, unit_per_item, unit_per_item_uom } = state.skusSelected;
        if (isItemKGAndNoneUnit(uom, unit_per_item)) {
          return '';
        }
        const itemsInProduct = checkWeightItem(unit_amount, uom);
        const unitsInItem = checkWeightItem(unit_per_item, unit_per_item_uom);
        return combineTwoUnit(itemsInProduct, unitsInItem);
      }
      return '';
    });

    const displayPriceInfo = computed(() => {
      return (
        (state.skusSelected && state.skusSelected.quotation_status.split(',').includes('received')) ||
        state.skusSelected.direct_price > 0
      );
    });

    const showPriceQuantity = computed(() => {
      return state.skusSelected
        ? priceFormatter(currencySymbol.value, displayPrice(state.skusSelected.price * state.orderQuantity))
        : priceFormatter(currencySymbol.value, displayPrice(0));
    });
    const showPriceWeight = computed(() => {
      return state.skusSelected
        ? priceFormatter(
            currencySymbol.value,
            displayPrice((state.skusSelected.price / state.skusSelected.weight) * +state.orderWeight)
          )
        : priceFormatter(currencySymbol.value, displayPrice(0));
    });
    const weight = computed(() => {
      return state.skusSelected?.sku_id ? state.skusSelected.weight + ' kg' : '';
    });
    const price = computed(() => {
      return state.skusSelected?.sku_id ? state.skusSelected.display_price : '-';
    });
    const isDirectPrice = computed(() => {
      return (
        state.skusSelected &&
        state.skusSelected.sku_id !== 0 &&
        state.skusSelected.direct_price > 0 &&
        state.skusSelected.direct_price === state.skusSelected.price
      );
    });
    const isSpecialPrice = computed(() => {
      return (
        !state.isOutOfStock &&
        state.skusSelected &&
        state.skusSelected.sku_id !== 0 &&
        state.skusSelected.direct_price > state.skusSelected.price
      );
    });
    const isBargainPrice = computed(() => {
      return (
        state.skusSelected &&
        state.skusSelected.sku_id !== 0 &&
        state.skusSelected.direct_price > 0 &&
        !isSpecialPrice.value &&
        state.skusSelected.quotation_status !== 'received'
      );
    });
    const pricekg = computed(() => {
      return priceFn(state.item, currencySymbol);
    });
    const needQuotationRequest = computed(() => {
      return (
        state.skusSelected &&
        state.skusSelected.quotation_status != QUOTATION_STATUS.RECEIVED &&
        state.skusSelected.sku_id !== 0 &&
        !isDirectPrice.value &&
        !state.item.booking_order_id &&
        state.isViewOnly !== 'view_only'
      );
    });
    const getSpecsByKey = computed(() => {
      return functions.sortListStrings(functions.getSpecsByKey(state.item));
    });
    const getSkusInfo = computed(() => {
      return functions.getSkusInfo(state.item);
    });
    const allowAttach = computed(() => {
      return state.skusSelected && state.skusSelected.sku_id !== 0 && state.specsSelected.length > 0;
    });
    const isDisabledAddToCartByWeight = computed(() => {
      const weight = +state.orderWeight;
      const quantity = +state.orderQuantity;
      if (state.skusSelected?.is_order_by_weight) {
        return weight <= 0;
      }
      return quantity <= 0;
    });
    const isDisableAddButton = computed(() => {
      return !isQuantityAMultipleOfIncrement({
        quantity: state.orderQuantity,
        increment: state.skusSelected?.increment_qty
      });
    });
    const colorGrayBtn = computed(() => {
      return (
        isDisabledAddToCartByWeight.value ||
        isDisableAddButton.value ||
        state.isOutOfStock ||
        !allowAttach.value ||
        disableOrderByWeight.value ||
        (checkZeroPrice.value && !allowAddZeroPrice.value)
      );
    });
    const packaging_size = computed(() => {
      return state.skusSelected &&
        state.skusSelected.uom !== DEFAULT_WEIGHT_UOM &&
        state.skusSelected.unit_amount &&
        state.skusSelected.uom
        ? state.skusSelected.unit_amount + ' ' + state.skusSelected.uom
        : '';
    });
    const priceKgBelowTotalPrice = computed(() => {
      return `${
        state.skusSelected?.is_order_by_weight ||
        state.skusSelected?.is_catch_weight ||
        state.skusSelected?.priced_by === QUOTED_PRICED_BY.WEIGHT
          ? state.skusSelected?.display_unit_price_per_weight
          : priceFormatter(
              currencySymbol.value,
              displayPrice(state.skusSelected?.price / state.skusSelected?.unit_amount)
            )
      } /${
        state.skusSelected?.is_order_by_weight ||
        state.skusSelected?.is_catch_weight ||
        state.skusSelected?.priced_by === QUOTED_PRICED_BY.WEIGHT
          ? DEFAULT_WEIGHT_UOM
          : state.skusSelected?.uom
      }`;
    });
    const isDisableBtn = computed(() => {
      return state.orderQuantity <= 0 || state.orderWeight <= 0;
    });
    const isBackOrder = computed(() => {
      return state.skusSelected?.is_back_order ?? null;
    });
    const disabledAddQuantity = computed(() => {
      return isBackOrder.value || remainingQuantity.value === null
        ? false
        : state.orderQuantity >= MAX_INPUT ||
            state.orderQuantity >= remainingQuantity.value ||
            remainingQuantity.value <= 0 ||
            state.skusSelected.is_out_of_stock;
    });
    const remainingQuantity = computed(() => {
      if (
        state.skusSelected.total_available_quantity < 0 ||
        state.skusSelected.total_available_quantity === null
      ) {
        return MAX_INPUT;
      } else {
        return state.skusSelected?.total_available_quantity ?? MAX_INPUT;
      }
    });
    //check remaining product
    const isShowRemaining = computed(() => {
      return state.skusSelected
        ? !isBackOrder.value && !state.isOutOfStock && remainingQuantity.value <= LIMIT_PRODUCT_REMAINING
        : false;
    });
    const directPrice = computed(() => {
      return state.skusSelected
        ? state.skusSelected.display_direct_price
        : priceFormatter(currencySymbol.value, displayPrice(0));
    });
    const directPriceAboveKg = computed(() => {
      return `${priceFormatter(
        currencySymbol.value,
        displayPrice(
          state.skusSelected?.price /
            (state.skusSelected?.uom === DEFAULT_WEIGHT_UOM
              ? state.skusSelected?.weight
              : state.skusSelected?.unit_amount)
        )
      )} /${
        state.skusSelected?.is_order_by_weight ||
        state.skusSelected?.is_catch_weight ||
        state.skusSelected?.priced_by === QUOTED_PRICED_BY.WEIGHT
          ? DEFAULT_WEIGHT_UOM
          : state.skusSelected?.uom
      }`;
    });
    const isPendingApprove = computed(() => {
      return (
        state.skusSelected &&
        state.skusSelected.sku_id !== 0 &&
        state.skusSelected.quotation_status === QUOTATION_STATUS.REQUESTED
      );
    });
    const disableOrderByWeight = computed(() => {
      return state.skusSelected?.is_order_by_weight && state.skusSelected.total_available_quantity
        ? state.orderWeight > state.skusSelected.total_available_quantity
        : false;
    });
    const showSimiliarProduct = computed(() => {
      return (
        state.isOutOfStock &&
        (state.skusSelected?.sku_id !== 0 || state.skusSelected !== null) &&
        state.specsSelected?.length > 0
      );
    });
    const showAddCartAndSelectVariation = computed(() => {
      return !showOosAndSelectOtherVariant.value && state.isViewOnly !== 'view_only';
    });
    const showContactToPurchase = computed(() => {
      return state.isViewOnly === 'view_only' && !showOosAndSelectOtherVariant.value;
    });
    const showOosAndSelectOtherVariant = computed(() => {
      return (
        (state.isOutOfStock && !needQuotationRequest.value && state.specsSelected.length > 0) ||
        state.isProductOos
      );
    });
    const showQuotation = computed(() => {
      return (
        state.isOutOfStock &&
        needQuotationRequest.value &&
        state.specsSelected.length > 0 &&
        state.isViewOnly !== 'view_only'
      );
    });
    const isGetOos = computed(() => {
      return CATEGORY_ENABLE_OOS.indexOf(state.selectedTab) > -1;
    });

    const checkZeroPrice = computed(() => {
      return state.skusSelected?.price <= 0;
    });

    const attributesData = computed(() => {
      let combineAttributes = null;
      if (
        state.skusSelected &&
        state.skusSelected?.attributes &&
        Object.keys(state.skusSelected?.attributes?.commonAttributes).length > 0
      ) {
        combineAttributes = {
          ...state.item?.attributes?.commonAttributes,
          ...state.skusSelected?.attributes.commonAttributes
        };
      } else {
        combineAttributes = {
          ...state.item?.attributes?.commonAttributes
        };
      }
      return combineAttributes;
    });

    const taggingSkusData = computed(() => {
      return Object.entries(attributesData.value).map(([key, value]) => ({ key, value }));
    });

    const mappedTaggingSkusData = computed(() => {
      return taggingSkusData.value.map(({ key, value }) => ({
        key: state.taggingSkuAttributes[key] || key,
        value
      }));
    });

    const isOpenAttributesInfo = ref(false);
    const setOpenAttributesInfo = (state) => {
      isOpenAttributesInfo.value = state;
    };
    const okButtons = [
      {
        text: 'OK',
        role: 'cancel'
      }
    ];
    const isOpenPriceUpdated = ref(false);
    const latesNewPrice = ref(null);
    const allowAddZeroPrice = ref(false);
    const { isSubscribed, toggleRef, getData, onToggleSubscribe } = useProductSubscribeNotification();

    const isAllSkusOrderByWeight = computed(() => {
      return state.item.skusData?.every((item) => item.is_order_by_weight);
    });
    const isAllOrderCatchWeight = computed(() => {
      return state.item.skusData?.every((item) => item.is_catch_weight);
    });
    const isAllOrderPricedByWeight = computed(() => {
      return state.item.skusData?.every((item) => item.priced_by === QUOTED_PRICED_BY.WEIGHT);
    });
    const isCalculateByWeight = computed(() => {
      return isAllSkusOrderByWeight.value || isAllOrderCatchWeight.value || isAllOrderPricedByWeight.value;
    });

    watch(
      () => state.specsSelected,
      () => {
        if (state.skusSelected && state.skusSelected.sku_id !== 0) {
          state.skusSelected?.is_out_of_stock ? (state.isOutOfStock = true) : (state.isOutOfStock = false);
          if (state.isOutOfStock) {
            state.oosVariable.mainCategoryId = state.selectedTab;
            state.oosVariable.isSingleVariant = state.item.is_single_variant;
            state.oosVariable.productId = state.item.id;
            state.oosVariable.tenantId = state.item.tenant_id;
            state.oosVariable.buyerId = state.selectedCompany.id;
          }
          state.isChangeSkuFavorite
            ? (state.isChangeSkuFavorite = false)
            : setOrderQuantity(state.skusSelected.increment_qty);
        } else {
          state.isOutOfStock = false;
        }
        // check this sku is available for add to cart with 0 price
        allowAddZeroPrice.value =
          state.skusSelected?.direct_price === 0 &&
          state.skusSelected?.limit_price === 0 &&
          state.item?.booking_order_id !== null;
      }
    );
    watch(
      () => state.isProductOos,
      async (isProductOosUpdate) => {
        const oosVar = state.oosVariable;
        if (isProductOosUpdate) {
          checkOtherVariation.value = true;
          await getProductRecommend(oosVar);
          setTimeout(() => {
            checkOtherVariation.value = false;
          }, 1000);
        }
      }
    );
    watch(oosList, (newOosList) => {
      similiarProductItems.value = newOosList?.oosList?.items;
      if ((showSimiliarProduct.value || state.isProductOos) && similiarProductItems.value?.length > 0) {
        // auto scroll to similar product list when the product is oos
        scrollToSimiliarProducts();
      }
    });

    watch(
      state.item,
      (productItemUpdated) => {
        state.item = productItemUpdated;
        reRenderProductImage.value++;
        updateSkusSelectedById(state.skusSelected?.sku_id);
        if (state.skusSelected.is_out_of_stock) {
          state.specsSelected.forEach((item) => {
            unCheckSpecItem(item);
          });
        }
      },
      { deep: true }
    );

    useBackButton(10, () => {
      backToPreviousPage();
    });
    return {
      state,
      storage,
      route,
      router,
      storefrontOutline,
      checkmarkOutline,
      chevronBackOutline,
      shareSocialOutline,
      closeOutline,
      removeCircleOutline,
      addCircleOutline,
      opacity,
      toggleModalBargainPrice,
      openToast,
      showAlert,
      priceDiscountUomFn,
      setTotalPricePerUomFn,
      priceFn,
      packagingSize,
      setOrderQuantity,
      changeSkusSelected,
      getProductDetail,
      showPriceInfo,
      changeSkuFavorite,
      checkOtherVariation,
      similiarProductItems,
      allowAddZeroPrice,
      handleContactToPurchase,
      handleContactNumber,
      contactToPurchase,
      updateSearch,
      getProductRecommend,
      resetProductRecommend,
      oosUpdateSearch,
      getCartItems,
      addItemToCart,
      resetCategory,
      setProductIdAfterExplore,
      exploreRelatedCategories,
      itemComputed,
      search,
      error,
      productIdAfterExplore,
      oosList,
      cart,
      getSpecsOosByKey,
      getSpecsOosById,
      isSample,
      formattedWeight,
      getSimiliarProductsData,
      showBookingId,
      bookingDateString,
      handleRefreshAndRefetchData,
      handleRefresh,
      getProductDetailData,
      backToPreviousPage,
      handleCheckLatetestPrice,
      addToCart,
      showAlertErrPrice,
      handleGetCustomerDetail,
      refDescription,
      productDetailContent,
      similiarProduct,
      checkGetLatestPrice,
      setOpenModalPriceUpdate,
      defaultSpecsSelected,
      handleShowHeader,
      handleDisplayShowMore,
      onInputWeight,
      minusQuantity,
      addQuantity,
      orderQuantityChange,
      updateSkusSelectedById,
      updateLocalRequestQuotation,
      setOtherVariantModal,
      unCheckSpecItem,
      checkSpecItem,
      getAllSkuIds,
      getAllowedSelectedSkusBySpecs,
      isAnySelectedIdIncluded,
      showOosAndBuyAgainStatus,
      checkNotAvailableSpecs,
      checkIsBuyAgainSpecs,
      selectSpec,
      scrollToSimiliarProducts,
      handlePendingApprove,
      updateLocalFavorite,
      onSelectSimilarProduct,
      onExploreOtherProducts,
      openSocialSharing,
      setSearchParamsBackToDefault,
      JOIN_STATUS,
      QUOTATION_STATUS,
      USER_TYPE,
      NOTIFICATION_TYPE,
      isOpenAttributesInfo,
      setOpenAttributesInfo,
      fromText,
      // icons
      paperPlaneOutline,
      informationCircleOutline,
      chatbubbleEllipsesOutline,
      notificationsOutline,
      heart,
      remove,
      add,
      // computed
      showPriceQuantity,
      showPriceWeight,
      displayPriceInfo,
      arrowDownOutline,
      weight,
      price,
      isDirectPrice,
      isSpecialPrice,
      isBargainPrice,
      pricekg,
      needQuotationRequest,
      getSpecsByKey,
      getSkusInfo,
      allowAttach,
      isDisabledAddToCartByWeight,
      colorGrayBtn,
      isDisableAddButton,
      packaging_size,
      priceKgBelowTotalPrice,
      isDisableBtn,
      disabledAddQuantity,
      isBackOrder,
      remainingQuantity,
      isShowRemaining,
      directPrice,
      directPriceAboveKg,
      isPendingApprove,
      currencySymbol,
      disableOrderByWeight,
      isOpenPriceUpdated,
      showSimiliarProduct,
      latesNewPrice,
      showAddCartAndSelectVariation,
      showContactToPurchase,
      showOosAndSelectOtherVariant,
      showQuotation,
      isGetOos,
      detectConnection,
      checkZeroPrice,
      attributesData,
      taggingSkusData,
      mappedTaggingSkusData,
      // social sharing
      Share,
      IMAGE_PATH,
      DEFAULT_PRODUCT_IMAGE,
      QUOTED_PRICED_BY,
      DEFAULT_WEIGHT_UOM,
      store,
      reRenderProductImage,
      clearQueryProductDetail,
      isIosPlatform: isPlatform('ios'),
      okButtons,
      isSubscribed,
      toggleRef,
      onToggleSubscribe,
      getData,
      isAllSkusOrderByWeight,
      isAllOrderCatchWeight,
      isAllOrderPricedByWeight,
      isCalculateByWeight
    };
  },

  async beforeRouteLeave(to, from, next) {
    const historyState = window.history.state;
    const prevHistoryPath = historyState?.back;
    // reset search params when user leave the product detail after add product to cart
    if (prevHistoryPath === '/b2b/search-products' && to.path === '/b2b/main/home') {
      await this.setSearchParamsBackToDefault();
    }
    next();
  }
});
</script>
<style src="./style.scss" lang="scss" scoped></style>
